import React, { useState } from "react";
import { css, styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";

import { setVolatileKV } from "@/app/actions.mjs";
import { appURLs } from "@/app/constants.mjs";

// All Apex resolutions
const resolutions = [
  { id: 0, width: 1600, height: 900 }, // 16:9
  { id: 1, width: 1980, height: 1080 }, // 16:9
  { id: 2, width: 1280, height: 1024 }, // 16:9
  { id: 3, width: 1600, height: 1080 }, // 16:10
  { id: 4, width: 1440, height: 1080 }, // 4:3/5:3
];

// Absolute positions
// Position: Benchmarking init
const RIGHT = (35 / 1440) * 100;
const BOT = (90 / 810) * 100;

// CSS Util
const CssSelect = () =>
  css({
    position: "absolute",
    top: "var(--sp-20)",
    left: "var(--sp-20)",
    zIndex: 100,
  });

export default function BenchmarkOverlayDebug({ children }) {
  const [option, setOption] = useState(resolutions[0]);
  const options = resolutions.map((i) => ({
    value: i.id,
    text: ["", `w: ${i.width}, h: ${i.height}`] as Translation,
  }));
  const handleOnChange = (id) => {
    const option = resolutions.find((i) => i.id === id);
    setOption(option);
    setVolatileKV("wndAttributes", option);
  };
  return (
    <OSWindow $width={option.width} $height={option.height}>
      <img src={`${appURLs.CDN}/blitz/apex/misc/apex-overlay-1980-1080.png`} />
      <GameWindow>
        <Target />
      </GameWindow>
      {children}
      <Select
        options={options}
        selected={option.id}
        onChange={handleOnChange}
        containerClassName={CssSelect()}
      />
    </OSWindow>
  );
}

const OSWindow = styled("div")<{ $width: number; $height: number }>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  background: black;
  position: relative;
  & > img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
  }
`;
const GameWindow = styled("div")`
  width: 100%;
  paddingbottom: ${(900 / 1600) * 100}%;
  background: var(--shade2);
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  opacity: 50%;
`;
const Target = styled("div")`
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: ${BOT}%;
  right: ${RIGHT}%;
  zindex: 100;
  background: blue;
`;
